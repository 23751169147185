
<script setup lang="ts">
const meta = {"title":"Vuetify — A Vue Component Framework","description":"Vuetify is a no design skills required Open Source UI Component Framework for Vue. It provides you with all of the tools necessary to create beautiful content rich web applications.","keywords":"vue, vue components, vue ui components, material design components, vuetify, component framework, component library"}
 const assets = undefined
 const backmatter = undefined
 const features = undefined
 const fluid = undefined
 const related = undefined
 const toc = [{"text":"Project Sponsors","to":"#project-sponsors","level":2},{"text":"Templates Built With Vuetify","to":"#templates-built-with-vuetify","level":2},{"text":"Made With Vuetify","to":"#made-with-vuetify","level":2}]
  defineExpose({ frontmatter: {"meta":{"title":"Vuetify — A Vue Component Framework","description":"Vuetify is a no design skills required Open Source UI Component Framework for Vue. It provides you with all of the tools necessary to create beautiful content rich web applications.","keywords":"vue, vue components, vue ui components, material design components, vuetify, component framework, component library"},"toc":[{"text":"Project Sponsors","to":"#project-sponsors","level":2},{"text":"Templates Built With Vuetify","to":"#templates-built-with-vuetify","level":2},{"text":"Made With Vuetify","to":"#made-with-vuetify","level":2}]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"meta":{"title":"Vuetify — A Vue Component Framework","description":"Vuetify is a no design skills required Open Source UI Component Framework for Vue. It provides you with all of the tools necessary to create beautiful content rich web applications.","keywords":"vue, vue components, vue ui components, material design components, vuetify, component framework, component library"},"toc":[{"text":"Project Sponsors","to":"#project-sponsors","level":2},{"text":"Templates Built With Vuetify","to":"#templates-built-with-vuetify","level":2},{"text":"Made With Vuetify","to":"#made-with-vuetify","level":2}]}
export const excerpt: string | undefined = ""

</script>
<template>
<div><home-entry></home-entry>
<br>
<home-features></home-features>
<br>
<home-special-sponsor></home-special-sponsor>
<br>
<section id="project-sponsors">
<app-heading href="#project-sponsors" level="2">Project Sponsors</app-heading>
<p style="max-width: 568px" class="mx-auto px-4">The continued development and maintenance of Vuetify is made possible by these generous sponsors:</p>
<home-sponsors></home-sponsors>
<v-divider style="max-width: 500px;" class="mx-auto my-16"></v-divider>
</section>
<section id="templates-built-with-vuetify">
<app-heading href="#templates-built-with-vuetify" level="2">Templates Built With Vuetify</app-heading>
<p style="max-width: 568px" class="mx-auto px-4">Check out these premium templates built using Vuetify.</p>
<doc-premium-themes-gallery></doc-premium-themes-gallery>
<v-divider style="max-width: 500px;" class="mx-auto my-16"></v-divider>
</section>
<section id="made-with-vuetify">
<app-heading href="#made-with-vuetify" level="2">Made With Vuetify</app-heading>
<p style="max-width: 568px" class="mx-auto px-4">Check out these beautiful apps, plugins, and themes built using Vuetify.</p>
<doc-made-with-vuetify-gallery class="pa-3 mb-4"></doc-made-with-vuetify-gallery>
<doc-made-with-vuetify-link></doc-made-with-vuetify-link>
<br>
<br>
<br>
</section>
</div>
</template>
